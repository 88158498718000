import {createRouter, createWebHistory} from 'vue-router'
import NProgress from 'nprogress'
//import Cookies from 'js-cookie'
import axios from 'axios'
import jwtDecode from "jwt-decode";
import {useAuthStore} from "@/store/authStore";
import Cookies from "js-cookie";
const routes = [
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login.vue'),
        meta: {requiresAuth: false}
    },
    {
        path: '/',
        component: () => import('../layout/Frame.vue'),
        meta: {requiresAuth: true},
        children: [
            {
                path: '',
                name: 'Tasks',
                component: () => import('../views/Tasklist.vue'),
                meta: {title: 'My Tasks', requiresAuth: true, requiresManagement: false, id: 1},
            },
            {
                path: "/multiview",
                name: "Multiview",
                component: () => import('../views/Multiview.vue'),
                meta: {title: 'Multiview', requiresAuth: true, requiresManagement: true, id: 2},
            },
            {
                path: "/settings",
                name: "Settings",
                component: () => import('../views/Settings.vue'),
                meta: {title: 'Settings', requiresAuth: true, requiresManagement: true, id: 3},
            },
            {
                path: "/racelists",
                name: "Racelists",
                component: () => import('../views/Racelists.vue'),
                meta: {title: 'Racelists', requiresAuth: true, requiresManagement: true, id: 4},
            },
            {
                path: "/racelists/:id",
                name: "Racelist",
                component: () => import('../views/Racelist.vue'),
                meta: {title: 'Racelist', requiresAuth: true, requiresManagement: true, id: 5},
            },
            {
                path: "/user/:id",
                name: "UserTasks",
                component: () => import('../views/Tasklist.vue'),
                meta: {title: 'User Tasks', requiresAuth: true, requiresManagement: false, id: 6},
            },
            {
                path: "/user/:id/completed",
                name: "CompletedTasks",
                component: () => import('../views/CompletedTasks.vue'),
                meta: {title: 'Completed Tasks', requiresAuth: true, requiresManagement: false, id: 7},
            },
            {
                path: "/user/:id/deleted",
                name: "DeletedTasks",
                component: () => import('../views/DeletedTasks.vue'),
                meta: {title: 'Deleted Tasks', requiresAuth: true, requiresManagement: false, id: 8},
            },
            {
                path: "/task/:id",
                name: "SingleTask",
                component: () => import('../views/SingleTask.vue'),
                meta: {title: 'Single Task', requiresAuth: true, requiresManagement: false, id: 9},
            },
        ]
    }
]

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes
});
router.beforeEach((to, _from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        const token = Cookies.get('token');
        if (!token) {
            const redirect = to.query.redirect ? "" : to.fullPath;
            next("/login?redirect=" + redirect);
            return;
        }

        const decodedJWT = jwtDecode(token);
        const expires = new Date(Math.floor(decodedJWT.exp) * 1000);
        const now = new Date();
        if(expires < now) {
            Cookies.remove('token');
            localStorage.clear();
            const redirect = to.query.redirect ? "" : to.fullPath;
            next("/login?redirect=" + redirect);
            return;
        } else {
            useAuthStore().getCurrentUser();
        }

        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    next();
});
/*router.beforeEach((to, from, next) => {
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
    if (nearestWithTitle) document.title = nearestWithTitle.meta.title;
    const authRequired = to.meta.requiresAuth;
    let loggedIn = localStorage.getItem("user") || false; //Cookies.get("user") || false;
    if(loggedIn) {
        loggedIn = JSON.parse(loggedIn);
        const decoded = jwt_decode(loggedIn.token);
        const now = Date.now() / 1000;
        if(decoded.exp < now) {
            console.log('not logged in')
            localStorage.removeItem("user")
            loggedIn = false;
            next("/login");
        }
    }
    const newTitle = to.meta.title;
    if(newTitle === undefined) {
        document.title = "Tasks";
    } else {
        document.title = "Tasks | " + to.meta.title;
    }
    if (authRequired && !loggedIn) {
        localStorage.setItem('redirect', to.fullPath);
        next("/login");
    } else {
        axios.defaults.headers.common["Authorization"] = "Bearer " + loggedIn.token;
        if(to.meta.requiresManagement && (loggedIn.manager !== "1" && loggedIn.manager !== 1 && loggedIn.manager !== "Y" && loggedIn.manager !== true && loggedIn.manager !== "true")) {
            next("/");
        } else {
            next();
        }
    }
});*/

router.beforeResolve((to, from, next) => {
    if (to.path) {
        NProgress.start()
    }
    next()
});

router.afterEach(() => {
    NProgress.done()
});

export default router
