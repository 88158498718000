import { createApp } from 'vue/dist/vue.esm-bundler';
import { createPinia } from 'pinia'
import VueProgressBar from "./libs/ProgressBar/index"//import VueProgressBar from "@aacassandra/vue3-progressbar";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import axios from 'axios';
import Antd from 'ant-design-vue';
import { message } from 'ant-design-vue';
import NProgress from 'nprogress';
//import keysearch from './libs/Keysearch/keysearch.umd.js';
import device from "current-device";
import Cookies from 'js-cookie'
import App from './App.vue'
import EditableCell from "./components/EditableCell.vue";
import Timer from "./components/Timer.vue";
import Task from "./components/Task.vue";
import Subtask from "./components/Subtask.vue";
import RacelistSubtask from "./components/RacelistSubtask.vue";
import TextEditor from "./components/TextEditor.vue";
import TextEditorWithView from "./components/TextEditorWithView.vue";
import Loader from "./components/Loader.vue";
import DynamicForm from "./components/DynamicForm.vue";
import PushRacelist from "./components/PushRacelist.vue";
import TransitionHeight from "./components/TransitionHeight.vue";
import KbdShortcutSymbol from "./components/KbdShortcutSymbol.vue";
import TopBar from "./components/TopBar.vue";
import Vue3TouchEvents from "vue3-touch-events";
import router from './router'
import store from './store'
import './sass/nprogress.css'
import './sass/style.scss'
//import './libs/Keysearch/keysearch.css';
import {useAuthStore} from "@/store/authStore";
//const iconSpriteLocation = require('./assets/remixicon.symbol.svg');
import iconSpriteLocation from './assets/remixicon.symbol.svg';
const progressBarOpts = {
    color: "#1990ff",
    failedColor: "#d40f0f",
    thickness: "3px",
    transition: {
        speed: "0.1s",
        opacity: "0.8s",
        termination: 300,
    },
    autoRevert: true,
    location: "top",
    inverse: false,
};
const app = createApp(App);
const pinia = createPinia()
Sentry.init({
    app,
    dsn: "https://1c91cc13403741e8a8eb94abc3144601@sentry.darksidedev.net/18",
    integrations: [
        new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: ["localhost", "tasks.darkside-developments.com", /^\//],
        }),
    ],
    tracesSampleRate: 0.4,
});

if (window.location.hostname === "localhost") {
    window.$apiUrl = "https://darkside_api.test/v2";
    app.config.globalProperties.$apiUrl = "https://darkside_api.test/v2";
} else {
    window.$apiUrl = "https://api.darkside-developments.com/v2";
    app.config.globalProperties.$apiUrl = "https://api.darkside-developments.com/v2";
}

axios.interceptors.request.use(function (config) {
    if(!config.url.includes('/break/status')) {
        NProgress.start();
    }
    return config;
}, function (error) {
    // Do something with request error
    console.log(error);
    return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
    NProgress.done();
    return response;
}, function (error) {
    console.log(error);
    return Promise.reject(error);
});

app.config.globalProperties.$iconSpriteLocation = iconSpriteLocation;
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$message = message;
app.config.globalProperties.$progress = NProgress;
app.config.globalProperties.$cookies = Cookies;
app.config.globalProperties.$device = device;
app.use(Antd);
app.use(store);
app.use(pinia);
app.use(router);
app.use(Vue3TouchEvents, {
    disableClick: true
});
//app.use(keysearch);
app.use(VueProgressBar, progressBarOpts)

app.component("Task", Task);
app.component("Subtask", Subtask);
app.component("RacelistSubtask", RacelistSubtask);
app.component("EditableCell", EditableCell);
app.component("Timer", Timer);
app.component("TextEditor", TextEditor);
app.component("TextEditorWithView", TextEditorWithView);
app.component("DynamicForm", DynamicForm);
app.component("PushRacelist", PushRacelist);
app.component("TransitionHeight", TransitionHeight);
app.component("KbdShortcutSymbol", KbdShortcutSymbol);
app.component("TopBar", TopBar);
app.component("loader", Loader);

app.mixin({
    computed: {
        $staff: {
            get() {
                return this.$store.state.staff;
            },
            set(val) {
                this.$store.commit("updateStaff", val);
            },
        },
        $onBreak: {
            get() {
                return this.$store.state.onBreak;
            },
            set(val) {
                this.$store.commit("updateBreakStatus", val);
            },
        },
        $currentStatus: {
            get() {
                return this.$store.state.currentStatus;
            },
            set(val) {
                this.$store.commit("updateCurrentStatus", val);
            },
        },
        $isManagement() {
            if(useAuthStore().user) {
                if(useAuthStore().user.is_management) {
                    return true;
                }
            }
            return false;
        }
    }
})

app.mount('#app')
