<template>
    <div class="editable-cell" @mouseover="showIcons = true" @mouseleave="showIcons = false">
        <div v-if="editable" class="editable-cell-input-wrapper">
            <a-input :value="value" @change="handleChange" @pressEnter="check" class="w-full rounded"/>
            <span class="flex gap-3 items-center">
                <check-outlined class="editable-cell-icon-check cursor-pointer" @click="check" v-if="showIcons"/>
                <close-outlined class="editable-cell-icon-check cursor-pointer" @click="cancel" v-if="showIcons"/>
            </span>
        </div>
        <div v-else class="editable-cell-text-wrapper">
            {{ value || ' ' }}
            <edit-outlined class="editable-cell-icon" @click="edit" v-if="showIcons"/>
        </div>
    </div>
</template>

<script>
import {CheckOutlined, CloseOutlined, EditOutlined} from '@ant-design/icons-vue';

export default {
    props: {
        modelValue: String,
    },
    components: {
        CheckOutlined,
        CloseOutlined,
        EditOutlined
    },
    data() {
        return {
            value: this.modelValue,
            editable: false,
            showIcons: false,
        };
    },
    methods: {
        handleChange(e) {
            this.value = e.target.value;
        },
        check() {
            this.editable = false;
            this.$emit('update', this.value);
        },
        edit() {
            this.editable = true;
        },
        cancel() {
            this.editable = false;
            this.value = this.modelValue
        }
    },
}
</script>

<style>
.editable-cell-input-wrapper {
    display: flex;
    align-items: center;
}
.editable-cell .anticon-edit {
    margin-left: 10px;
}
.editable-cell input{
    width: 90%;
    margin-right: 10px;
}
</style>
