<template>
    <div class="flex items-center gap-2">
        <a-button class="flex items-center justify-center" @click="toggleTimer">
            <template #icon>
                <svg viewBox="0 0 24 24" fill="currentColor" class="h-4">
                    <use :xlink:href="$iconSpriteLocation + '#ri-play-fill'" v-if="!isPlaying"></use>
                    <use :xlink:href="$iconSpriteLocation + '#ri-stop-fill'" v-else></use>
                </svg>
            </template>
        </a-button>
        <div class="text-xs" :class="{'playing': isPlaying}" v-if="renderStopwatch !== 0">{{ renderStopwatch }}</div>
    </div>
</template>

<script>
import {defineComponent} from 'vue';
import moment from 'moment';
export default defineComponent({
    props: ['playing', 'task', 'userid'],
    data() {
        return {
            isPlaying: false,
            stopwatch: 0,
            renderStopwatch: 0,
            renderHours: 0,
            renderMinutes: 0,
            renderSeconds: 0,
            datas: [],
            stopwatchTimer: 0,
            sH: 0,
            sM: 0,
            sS: 0,
            interval1: null,
            interval2: null,
        }
    },
    emits: ['started', 'stopped'],
    mounted() {
        if(this.task.times) {
            for (let i = 0; i < this.task.times.length; i++) {
                const time = this.task.times[i];
                if(time.pause_time === null && time.user === this.userid) {
                    const start = moment(time.start_time)
                    const now = moment();
                    this.isPlaying = true;
                    this.stopwatch = now.diff(start)/1000
                    this.startTimer()
                }
            }
        }
    },
    methods: {
        toggleTimer() {
            if(this.isPlaying) {
                this.stopTimer()
                this.isPlaying = false;
                this.$emit('stopped');
            } else {
                this.startTimer()
                this.isPlaying = true;
                this.$emit('started');
            }
        },
        startTimer() {
            this.interval1 = setInterval(() => {
                this.updateNewStopwatch();
            }, 1000);

            this.interval2 = setInterval(() => {
                this.stopwatch = this.stopwatch + 1;
                this.renderHours = Math.round(this.stopwatch / 3600);
                this.renderMinutes = Math.round(this.stopwatch / 60);
                this.renderSeconds = Math.round(this.stopwatch % 60);

                if (this.renderHours < 10) this.renderHours = '0' + this.renderHours;
                if (this.renderMinutes < 10) this.renderMinutes = '0' + this.renderMinutes;
                if (this.renderSeconds < 10) this.renderSeconds = '0' + this.renderSeconds;
                this.renderStopwatch = this.renderHours + ':' + this.renderMinutes + ':' + this.renderSeconds;
            }, 1000);
        },
        stopTimer() {
            console.log('stoppingTimer')
            clearInterval(this.interval1)
            clearInterval(this.interval2)
            this.isPlaying = false;
            this.resetStopwatch();
        },
        resetStopwatch() {
            this.stopwatch = 0;
            this.renderHours = 0;
            this.renderMinutes = 0;
            this.renderSeconds = 0;
            this.renderStopwatch = 0;//this.renderHours + ':' + this.renderMinutes + ':' + this.renderSeconds;
        },
        updateNewStopwatch() {
            this.sS++;
            if (this.sS >= 60) {
                this.sS = 0;
                this.sM++;
                if (this.sM >= 60) {
                    this.sM = 0;
                    this.sH++;
                }
            }
            if (this.sH < 10) { '0' + this.sH; }
            if (this.sM < 10) { '0' + this.sM; }
            if (this.sS < 10) { '0' + this.sS; }
            this.stopwatchTimer = this.sH + ':' + this.sM + ':' + this.sS;
        }
    },
    watch: {
        playing(val) {
            this.isPlaying = !!val;
        }
    }
});
</script>
<style lang="scss">

.playing {
    text-align: center;
    margin: 0 auto;
    width: 50%;
    animation-duration: 1s;
    animation-name: pulsate;
    animation-iteration-count: infinite;
}
@keyframes pulsate {
    0% {
        color: #FF0000;
    }
    50% {
        color: #000;
    }
    100% {
        color: #FF0000;
    }
}
</style>
