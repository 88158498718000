<template>
    <tr class="subtask-row">
        <td style="width: 80px" v-if="!disableReorder" class="pl-3">
            <span class="mx-auto block subtask-handle">
                <svg viewBox="0 0 24 24" fill="currentColor" class="remix h-3 mx-auto">
                    <use :xlink:href="$iconSpriteLocation + '#ri-grid-fill'"></use>
                </svg>
            </span>
        </td>

        <td style="width: 80px" :class="{'pl-3' : disableReorder}">
            <a-checkbox v-if="element.title" v-model:checked="element.pending_completed" @change="updateCompletedSubtask(element)" class="mr-2"></a-checkbox>
            <a-checkbox v-else v-model:checked="element.pendin_complete" @change="updateCompletedSubtask(element)" class="mr-2"></a-checkbox>
        </td>

        <td style="text-align:left;min-width:100px; width:100%">
            <span class="font-semibold w-full" v-if="element.title">
                <a-input v-model:value="element.title" @blur="updateSubtaskTitle" style="width: 100%"/>
            </span>
            <span class="font-semibold" v-else>{{ element.part_name }}</span>
            <text-editor-with-view ref="notes" v-model="element.note" @blur="updateNotes" no-label no-buttons no-placeholder @blurred="notesUpdated"></text-editor-with-view>
        </td>

        <td style="width: 150px">
            <div class="flex items-center gap-3 justify-center px-2">
                <a-button class="flex items-center justify-center" @click="toggleNotes" :class="{'active': notesOpen}">
                    <template #icon>
                        <svg viewBox="0 0 24 24" fill="currentColor" class="h-4">
                            <use :xlink:href="$iconSpriteLocation + '#ri-pencil-line'"></use>
                        </svg>
                    </template>
                </a-button>

                <a-popconfirm title="Are you sure delete this subtask?" ok-text="Yes" cancel-text="No" @confirm="deleteSubtask(element)">
                    <a-button class="flex items-center justify-center" danger>
                        <template #icon>
                            <svg viewBox="0 0 24 24" fill="currentColor" class="h-4">
                                <use :xlink:href="$iconSpriteLocation + '#ri-delete-bin-6-line'"></use>
                            </svg>
                        </template>
                    </a-button>
                </a-popconfirm>
            </div>
        </td>

        <td style="width: 50px"></td>

        <td style="width: 200px" class="pr-3">
            <a-select ref="select" v-model:value="element.owner" style="width: 120px" :options="$staff" :field-names="{ label: 'nicename', value: 'id' }" class="text-xs" @change="updateSubtaskOwner($event, element)"></a-select>
        </td>
    </tr>
</template>

<script>
export default {
    props: ['task', 'subtask', 'disableReorder'],
    data() {
        return {
            element: this.subtask,
            notesOpen: false,
        }
    },
    mounted() {},
    emits: ['updatedSubtask'],
    computed: {
    },
    methods: {
        toggleNotes() {
            this.notesOpen = !this.notesOpen;
            this.$refs.notes.canEdit = !this.$refs.notes.canEdit
        },
        updateNotes() {
            this.$axios.put(this.$apiUrl + '/tasklist/tasks/' + this.task.id + '/subtasks/' + this.element.id + '/update/note',{
                value: this.element.note
            }).then(response => {
                console.log(response.data)
                this.$emit('updatedSubtask');
            }).catch(e => {
                console.log(e)
            })
        },
        updateCompletedSubtask() {
            let isJobpart = false;
            let isComplete = this.element.pending_completed;
            if(Object.prototype.hasOwnProperty.call(this.element, 'sort_order')) {
                isJobpart = true;
                isComplete = this.element.pendin_complete;
            }
            this.$axios.put(this.$apiUrl + '/tasklist/tasks/' + this.task.id + '/subtasks/' + this.element.id + '/update/completion',{
                status: isComplete,
                owner: this.element.owner,
                jobpart: isJobpart
            }).then(resp => {
                console.log(resp.data)
            }).catch(e => {
                console.log(e)
            })
        },
        updateSubtaskOwner(e) {
            console.log('updating subtask owner')
            let isJobpart = false;
            if(Object.prototype.hasOwnProperty.call(this.element, 'sort_order')) {
                isJobpart = true;
            }
            this.$axios.put(this.$apiUrl + '/tasklist/tasks/' + this.task.id + '/subtasks/' + this.element.id + '/update/owner',{
                owner: e,
                jobpart: isJobpart
            }).then(response => {
                console.log(response.data)
                this.$emit('refresh')
            }).catch(e => {
                console.log(e)
            })
        },
        updateSubtaskTitle(e) {
            let isJobpart = false;
            if(Object.prototype.hasOwnProperty.call(this.element, 'sort_order')) {
                isJobpart = true;
            }
            this.$axios.put(this.$apiUrl + '/tasklist/tasks/' + this.task.id + '/subtasks/' + this.element.id + '/update/title',{
                value: e.target.value,
                jobpart: isJobpart
            }).then(response => {
                if(response.data.success) {
                    this.$emit('refresh')
                }
            }).catch(e => {
                console.log(e)
            })
        },
        deleteSubtask(subtask) {
            let isJobpart = false;
            if(Object.prototype.hasOwnProperty.call(subtask, 'sort_order')) {
                isJobpart = true;
            }
            subtask.task_id = this.task.id;
            this.$axios.delete(this.$apiUrl + '/tasklist/tasks/' + this.task.id + '/subtasks/' + this.element.id + '/delete?jobpart='+isJobpart).then(resp => {
                if(resp.data.success) {
                    console.log(resp.data)
                    this.$emit('updatedSubtask');
                } else {
                    console.log(resp.data)
                }
            }).catch(e => {
                console.log(e)
            })
        },
        notesUpdated() {
            this.notesOpen = false
            this.$refs.notes.canEdit = false
        }
    }
}
</script>

<style lang="scss">
</style>
