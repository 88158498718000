<template>
    <div v-if="loading" class="h-screen2 flex items-center justify-center">
        <svg class="loading-spinner" width="32px" height="32px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
        </svg>
    </div>
</template>

<script>
export default {
    props: {
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
        }
    },
    mounted() {},
    computed: {},
    methods: {}
}
</script>

<style lang="scss">
</style>
