import {createStore} from "vuex";
import {auth} from "./auth.module";

export default createStore({
    modules: {
        auth
    },
    state: {
        staff: [],
        onBreak: false,
        currentStatus: null,
    },
    mutations: {
        updateStaff(state, val) {
            Object.assign(state.staff, val)
        },
        updateBreakStatus(state, val) {
            state.onBreak = val;
        },
        updateCurrentStatus(state, val) {
            state.currentStatus = val;
        }
    }
});
