<template>
    <div class="">
        <editor-content :editor="editor" />
    </div>
</template>

<script>
import {defineComponent} from 'vue';
import { Editor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'
export default defineComponent({
    props: {
        modelValue: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: ""
        }
    },
    components: {
        EditorContent,
    },
    data() {
        return {
            editor: null,
        }
    },
    mounted() {
        const self = this;
        this.editor = new Editor({
            content: this.modelValue,
            extensions: [
                StarterKit,
                Placeholder.configure({
                    placeholder: self.placeholder,
                }),
            ],
            onUpdate: () => {
                this.$emit('update:modelValue', this.editor.getHTML())
                // JSON
                // this.$emit('update:modelValue', this.editor.getJSON())
            },
            onBlur: (e) => {
                this.$emit('blur', e)
            }
        })
    },
    beforeUnmount() {
        this.editor.destroy()
    },
    watch: {
        modelValue(value) {
            const isSame = this.editor.getHTML() === value
            // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)
            if (isSame) {
                return
            }
            this.editor.commands.setContent(value, false)
        },
    },

});
</script>

<style>
/* Placeholder (at the top) */
.ProseMirror p.is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    float: left;
    color: #adb5bd;
    pointer-events: none;
    height: 0;
}
</style>
