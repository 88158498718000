<template>
    <div class="">
        <h4 class="font-semibold mb-2 text-xs" v-if="!noButtons || !noLabel">
            <template v-if="!noLabel">Notes</template>
            <a-button size="small" class="ml-4" v-if="!canEdit && !noButtons" @click="canEdit = true">Edit</a-button>
            <a-button size="small" class="ml-4" v-if="canEdit && !noButtons" @click="canEdit = false">Cancel</a-button>
        </h4>
        <div class="note-content" v-html="textValue" v-if="!canEdit"></div>
        <editor-content ref="editor" v-if="canEdit" :editor="editor"/>
        <div v-if="notesEmpty && !canEdit && !noPlaceholder" class="opacity-70">No Notes</div>
    </div>
</template>

<script>
import {defineComponent} from 'vue';
import { Editor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'
import _ from "lodash";
import Autolinker from "autolinker";
export default defineComponent({
    props: {
        modelValue: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: ""
        },
        noLabel: {
            type: Boolean,
            default: false
        },
        noButtons: {
            type: Boolean,
            default: false
        },
        active: {
            type: Boolean,
            default: false
        },
        noPlaceholder: {
            type: Boolean,
            default: false
        }
    },
    components: {
        EditorContent,
    },
    data() {
        return {
            canEdit: this.active ?? false,
            editor: null,
            textValue: this.modelValue
        }
    },
    emits: ['update:modelValue', 'blur', 'blurred'],
    mounted() {
        this.textValue = this.formatNotes(this.textValue)
        const self = this;
        this.editor = new Editor({
            content: this.modelValue,
            extensions: [
                StarterKit,
                Placeholder.configure({
                    placeholder: self.placeholder,
                }),
            ],
            onUpdate: () => {
                this.$emit('update:modelValue', this.editor.getHTML())
                this.textValue = this.editor.getHTML()
            },
            onBlur: (e) => {
                this.$emit('blur', e)
                this.$emit('blurred', e);
            }
        })
    },
    methods: {
        updateEdit() {

        },
        formatNotes(s) {
            if(s !== "" && s !== null && s !== undefined) {
                s = s.replace(/Â\s/g,'');
                s = s.replace(/^\s*/g,'');
                s = s.replace(/<p><br><\/p>/g,'');
                s = _.unescape(s);
                return Autolinker.link(s)
            } else {
                return s
            }
        },
    },
    beforeUnmount() {
        this.editor.destroy()
    },
    computed: {
        notesEmpty() {
            if(!this.editor) {
                return true
            }
            return !!this.editor.isEmpty;
        }
    },
    watch: {
        modelValue(value) {
            const isSame = this.editor.getHTML() === value
            // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)
            if (isSame) {
                return
            }
            this.editor.commands.setContent(value, false)
        },
    },

});
</script>

<style>
/* Placeholder (at the top) */
.ProseMirror p.is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    float: left;
    color: #adb5bd;
    pointer-events: none;
    height: 0;
}
.note-content a[href] {
    color: #007bff;
}
</style>
