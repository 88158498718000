import { defineStore } from 'pinia'
import router from "@/router";
import Cookies from 'js-cookie'
import { useStorage } from '@vueuse/core'
import axios from "axios";

const initialState = {
    authenticated: false,
    token: "",
    user: null,
};

export const useAuthStore = defineStore('auth', {
    state: () => useStorage('auth', initialState),
    actions: {
        login(state, payload) {
            console.log(state, payload)
        },
        async logout() {
            if (window.location.hostname === "localhost") {
                Cookies.remove("token", {domain: "localhost"});
            } else {
                Cookies.remove("token", {domain: ".darkside-developments.com"});
            }
            localStorage.clear();
            await router.push("/login")
        },
        async loginSuccessfull(payload) {
            if (window.location.hostname === "localhost") {
                Cookies.set("token", payload.token, {expires: 12, domain: "localhost", secure: true, sameSite: 'None'});
            } else {
                Cookies.set("token", payload.token, {expires: 12, domain: ".darkside-developments.com"});
            }
            this.authenticated = true;
            this.token = payload.token;
            this.user = payload;
            if (router.currentRoute.value.query.redirect) {
                await router.push(router.currentRoute.value.query.redirect.toString());
            } else {
                console.log('should go to main')
                await router.push("/");
            }
        },
        getCurrentUser() {
            axios.get(window.api_url + "/v2/user", {
                headers: {
                    Authorization: "Bearer " + Cookies.get("token")
                }
            }).then((resp) => {
                this.token = Cookies.get("token");
                this.authenticated = true;
                this.user = resp.data.data;
            })
        }
    }
})
