<template>
    <a-form ref="formRef" name="dynamic_form_item" :model="dynamicValidateForm" v-bind="formItemLayoutWithOutLabel">
        <a-form-item
            v-for="(field, index) in dynamicValidateForm.fields"
            :key="field.key"
            v-bind="index === 0 ? formItemLayout : {}"
            :name="['fields', index, 'value']"
            :rules="{required: true, message: 'Field can not be null', trigger: 'change'}"
        >
            <a-input v-model:value="field.value" :placeholder="placeholder" style="width: 60%; margin-right: 8px" size="small"/>
            <MinusCircleOutlined v-if="dynamicValidateForm.fields.length > 0" class="dynamic-delete-button" :disabled="dynamicValidateForm.fields.length === 1" @click="removeField(field)"/>
        </a-form-item>

        <a-form-item v-bind="formItemLayoutWithOutLabel">
            <a-button type="dashed" style="width: 60%" @click="addField" size="small">
                <PlusOutlined />
                {{ btnTxt }}
            </a-button>
        </a-form-item>
        <a-form-item v-bind="formItemLayoutWithOutLabel" v-if="!noSubmit">
            <a-button type="primary" html-type="submit" @click="submitForm" size="small">Update</a-button>
            <!--<a-button style="margin-left: 10px" @click="resetForm" size="small">Reset</a-button>-->
        </a-form-item>
    </a-form>
</template>

<script>

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons-vue';
import { defineComponent, reactive, ref } from 'vue';
export default defineComponent({
    props: {
        modelValue: {
            type: Array,
            default: () => []
        },
        placeholder: {
            type: String,
            default: ""
        },
        btnTxt: {
            type: String,
            default: "Add Field"
        },
        name: {
            type: String,
            default: "field"
        },
        noSubmit: {
            type: Boolean,
            default: false
        }
    },
    components: {
        MinusCircleOutlined,
        PlusOutlined,
    },
    emits: ['submit'],
    setup(props, context) {
        const formRef = ref();
        const formItemLayout = {
            labelCol: {
                xs: {
                    span: 24,
                },
                sm: {
                    span: 4,
                },
            },
            wrapperCol: {
                xs: {
                    span: 24,
                },
                sm: {
                    span: 20,
                },
            },
        };
        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 20,
                    offset: 4,
                },
            },
        };
        const dynamicValidateForm = reactive({
            fields: props.modelValue,
        });

        const submitForm = () => {
            formRef.value.validate().then(() => {
                context.emit('submit', dynamicValidateForm.fields);
            }).catch(error => {
                console.log('error', error);
            });
        };

        const resetForm = () => {
            formRef.value.resetFields();
        };

        const removeField = item => {
            let index = dynamicValidateForm.fields.indexOf(item);

            if (index !== -1) {
                dynamicValidateForm.fields.splice(index, 1);
            }
        };

        const addField = () => {
            dynamicValidateForm.fields.push({
                value: '',
                key: Date.now(),
            });
        };

        return {
            formRef,
            formItemLayout,
            formItemLayoutWithOutLabel,
            dynamicValidateForm,
            submitForm,
            resetForm,
            removeField,
            addField,
        };
    },

});
</script>
<style>
.dynamic-delete-button {
    cursor: pointer;
    position: relative;
    top: 4px;
    font-size: 24px;
    color: #999;
    transition: all 0.3s;
}
.dynamic-delete-button:hover {
    color: #777;
}
.dynamic-delete-button[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
}
.ant-form .ant-form-item {
    margin-bottom: 0px;
}
.ant-btn .anticon {
    position: relative;
    top: -2px;
}

.ant-form-item-control-input-content .anticon {
    position: relative;
    top: -2px;
}
.ant-form-horizontal .ant-col {
    margin-left: 0 !important;
}
.ant-form-item-control-input-content span.anticon.dynamic-delete-button {
    font-size: 20px;
}
</style>
