<template>
    <a-select size="small"  ref="select" show-search v-model:value="selectedUser" style="width: 200px" :options="$staff" :field-names="{ label: 'nicename', value: 'id' }"></a-select>
    <a-button size="small" type="primary" :disabled="!selectedUser" @click="pushToUser" :loading="pushingToUser">Push to User</a-button>
</template>

<script>
export default {
    props: {
        taskId: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            selectedUser: null,
            pushingToUser: false,
        }
    },
    methods: {
        pushToUser() {
            const taskId = this.taskId;
            const user = this.selectedUser;
            this.pushingToUser = true;
            this.$axios.post(this.$apiUrl + '/tasklist/racelists/push',{
                id: taskId,
                user: user
            }).then(resp => {
                if(resp.data.success){
                    alert('Pushed racelist task to user')
                    this.pushingToUser = false;
                    this.selectedUser = null;
                } else {
                    this.pushingToUser = false;
                }
            }).catch(e => {
                console.log(e)
                this.pushingToUser = false;
            });
        }
    }
}
</script>
