<template>
    <div class="main-body min-h-screen">
        <router-view/>
        <vue-progress-bar></vue-progress-bar>
    </div>
</template>
<script>
import {defineComponent} from 'vue';
import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";
import {useAuthStore} from "@/store/authStore";
export default defineComponent({
    data() {
        return {
            tokenCheckInterval: null
        }
    },
    created() {
        this.$axios.interceptors.response.use((response) => {
            return response;
        }, (error) => {
            if(error.response !== undefined) {
                if (401 === error.response.status) {
                    this.$store.dispatch('auth/logout');
                } else {
                    return Promise.reject(error);
                }
            } else {
                return Promise.reject(error);
            }
        });
        this.tokenCheckInterval = setInterval(() => {
            if(useAuthStore().user && useAuthStore().token && this.$route.name !== "Login") {
                const decodedToken = jwt_decode(useAuthStore().token);
                const now = Date.now() / 1000;
                if(decodedToken.exp < now) {
                    console.log("Token Expired")
                    Cookies.remove("user");
                    Cookies.remove("token");
                    localStorage.removeItem("user");
                    location.reload();
                }
            }
        }, 10000)
    },
    unmounted() {
        if(this.tokenCheckInterval) {
            clearInterval(this.tokenCheckInterval);
        }
    }
});
</script>
<style lang="scss">
</style>
