<template>
    <div class="pl-3 flex-none text-xs font-semibold opacity-70" v-if="$device.os === 'macos'">
        ⌘{{ kbdKey }}
    </div>
    <div class="pl-3 flex-none text-xs font-semibold opacity-70" v-if="$device.os === 'windows'">
        <span class="text-gray-600 mr-1">Ctrl</span>{{ kbdKey }}
    </div>
</template>

<script>
export default {
    props: ['kbdKey'],
    data() {
        return {
            keys: {
                control: {
                    macos: '^',
                    windows: 'Ctrl'
                },
                option: {
                    macos: '⌥',
                    windows: 'Alt'
                },
                command: {
                    macos: '⌘',
                    windows: 'Ctrl'
                },
                shift: {
                    macos: '⇧',
                    windows: 'Shift'
                },
                space: {
                    macos: '␣',
                    windows: 'Space'
                },
                return: {
                    macos: '↩',
                    windows: 'Enter'
                }
            }
        }
    },
    mounted() {
    }
}
</script>

<style lang="scss">
</style>
