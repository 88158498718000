import axios from "axios";
import Cookies from 'js-cookie'
if (window.location.hostname == "localhost") {
	window.api_url = "https://darkside_api.test";//"http://localhost:8000";
} else {
	window.api_url = "https://api.darkside-developments.com";
}
if(Cookies.get("token")) {
	window.token = Cookies.get("token");
} else {
	window.token = null;
}
const Auth_URL = window.api_url + "/api/auth";

Date.prototype.addHours = function(h) {
	this.setHours(this.getHours() + h);
	return this;
};

Storage.prototype.setObject = function(key, value) {
	this.setItem(key, JSON.stringify(value));
}

Storage.prototype.getObject = function(key) {
	return JSON.parse(this.getItem(key));
}

class AuthService {
	login(user) {
		return axios
			.post(Auth_URL, {
				username: user.username,
				password: user.password,
			})
			.then((response) => {
				if (response.data.success && response.data.success === true) {
					let access_token = response.data.data.user.token;
					window.token = access_token;
					axios.defaults.headers.common["Authorization"] = "Bearer " + access_token;
					//const expires = new Date().addHours(12).toUTCString();

					localStorage.setObject("user", response.data.data.user);
					if (window.location.hostname === "localhost") {
						Cookies.set("token", access_token, {expires: 12, domain: "localhost", secure: true, sameSite: 'None'});
						//Cookies.set("user", JSON.stringify(response.data.data.user), {expires: 12, domain: "localhost", secure: true, sameSite: 'None'});
					} else {
						//Cookies.set("user", JSON.stringify(response.data.data.user), {expires: 12, domain: ".darkside-developments.com"});
						Cookies.set("token", access_token, {expires: 12, domain: ".darkside-developments.com"});
					}
				}
				return response.data;
			});
	}

	logout() {
		Cookies.remove("user");
		Cookies.remove("token");
		localStorage.removeItem("user");
	}

	register(user) {
		return this.$http.post(Auth_URL + "signup", {
			username: user.username,
			email: user.email,
			password: user.password,
		});
	}
}

export default new AuthService();
