<template>
    <div class="border-b border-slate-200">
        <div class="flex items-center p-2">
            <span class="handle pl-3">
                <svg viewBox="0 0 24 24" fill="currentColor" class="remix h-3 mr-2">
                    <use :xlink:href="$iconSpriteLocation + '#ri-grid-fill'"></use>
                </svg>
            </span>
            <!--<a-checkbox v-model:checked="subtask.pending_completed" class="mr-2" @change="updateCompletion"></a-checkbox>-->
            <span class="font-semibold w-full" :class="{'line-through opacity-80': subtask.pending_completed}">
                <!--<EditableCell v-model="subtask.title" @update="updateTitle"></EditableCell>-->
                <a-input v-model:value="subtask.title" size="small" @blur="updateTitle" style="width: 80%"></a-input>
            </span>

            <div class="flex items-center gap-3 ml-auto">
                <a-button size="small" class="flex items-center justify-center" title="Edit Notes" @click="showNoteEditor = !showNoteEditor" :class="{'active': showNoteEditor}">
                    <template #icon>
                        <svg viewBox="0 0 24 24" fill="currentColor" class="h-4">
                            <use :xlink:href="$iconSpriteLocation + '#ri-pencil-line'"></use>
                        </svg>
                    </template>
                </a-button>

                <a-popconfirm title="Are you sure delete this subtask?" ok-text="Yes" cancel-text="No" @confirm="deleteSubtask">
                    <a-button size="small" class="flex items-center justify-center" danger>
                        <template #icon>
                            <svg viewBox="0 0 24 24" fill="currentColor" class="h-4">
                                <use :xlink:href="$iconSpriteLocation + '#ri-delete-bin-6-line'"></use>
                            </svg>
                        </template>
                    </a-button>
                </a-popconfirm>
            </div>
        </div>

        <div class="ml-10 py-1" v-html="subtask.note" v-if="subtask.note && !showNoteEditor"></div>

        <div class="task-notes border-t px-2 py-3" v-if="showNoteEditor">
            <text-editor-with-view v-model="subtask.note" @blur="updateNotes" no-label no-buttons active placeholder="Notes..."></text-editor-with-view>
        </div>
    </div>
</template>

<script>
import _ from "lodash";
import Autolinker from "autolinker";
export default {
    props: ['element', 'task'],
    components: {
    },
    data() {
        return {
            subtask: this.element,
            showNoteEditor: false
        }
    },
    emits: ['deleted'],
    methods: {
        deleteSubtask() {
            this.$axios.delete(this.$apiUrl + '/tasklist/racelists/' + this.task.id + '/subtasks/' + this.subtask.id + '/delete').then(resp => {
                if(resp.data.success) {
                    this.$emit('deleted')
                } else {
                    console.log(resp.data)
                }
            }).catch(e => {
                console.log(e)
            })
        },
        updateNotes() {
            this.$axios.put(this.$apiUrl + '/tasklist/racelists/' + this.task.id + '/subtasks/' + this.subtask.id + '/update/note',{
                note: this.subtask.note
            }).then(resp => {
                if(resp.data.success){
                    this.showNoteEditor = false
                    this.subtask.note = this.formatNotes(this.subtask.note);
                } else {
                    console.log(resp.data)
                }
            }).catch(e => {
                console.log(e)
            })
        },
        updateTitle(e) {
            this.$axios.put(this.$apiUrl + '/tasklist/racelists/' + this.task.id + '/subtasks/' + this.subtask.id + '/update/title',{
                value: e.target.value
            }).then(resp => {
                if(resp.data.success){
                    console.log(resp.data)
                } else {
                    console.log(resp.data)
                }
            }).catch(e => {
                console.log(e)
            })
        },
        updateCompletion(e) {
            this.$axios.put(this.$apiUrl + '/tasklist/racelists/' + this.task.id + '/subtasks/' + this.subtask.id + '/update/completion',{
                value: e.target.checked
            }).then(resp => {
                if(resp.data.success){
                    console.log(resp.data)
                } else {
                    console.log(resp.data)
                }
            }).catch(e => {
                console.log(e)
            })
        },
        formatNotes(s) {
            if(s !== "" && s !== null && s !== undefined) {
                s = s.replace(/Â\s/g,'');
                s = s.replace(/^\s*/g,'');
                s = s.replace(/<p><br><\/p>/g,'');
                s = _.unescape(s);
                return Autolinker.link( s )
            } else {
                return s
            }
        },
    }
}
</script>
