<template>
    <div v-if="element">
        <div class="task" :data-task-id="task.id" :class="{'owned': task.owner === user.id, 'completed_task' : task.pending_completed === 1 || task.pending_completed === true, 'in-progress': playing, 'in-progress': taskIsInProgress}">
            <div class="flex items-center px-2 py-2 task-view mb-2 md:mb-0" :class="{'multiview': multiview}">
                <div class="flex items-center w-full">
                    <span class="handle pl-2" v-if="!disableReorder">
                    <svg viewBox="0 0 24 24" fill="currentColor" class="remix h-3 mr-2">
                        <use :xlink:href="$iconSpriteLocation + '#ri-grid-fill'"></use>
                    </svg>
                </span>
                    <a-checkbox size="default" v-model:checked="task.pending_completed" class="mr-2" @change="updateCompleted" :class="{'ml-2': disableReorder}"></a-checkbox>
                    <div v-if="task.subtasks && task.subtasks.length > 0" class="mr-2 flex items-center gap-2">
                            <a-button class="flex items-center justify-center p-1" @click="showSubtasks(task)">
                                <svg viewBox="0 0 24 24" fill="currentColor" class="h-full">
                                    <use :xlink:href="$iconSpriteLocation + '#ri-indeterminate-circle-line'" v-if="task.showSubtasks"></use>
                                    <use :xlink:href="$iconSpriteLocation + '#ri-add-circle-line'" v-else></use>
                                </svg>
                            </a-button>

                            <a-button @click="showOnlyOwnSubtasks = !showOnlyOwnSubtasks" v-if="task.showSubtasks" :class="{'active': showOnlyOwnSubtasks}" class="flex items-center justify-center p-1">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-full"><path fill="none" d="M0 0h24v24H0z"/><path
                                    d="M20 22h-2v-2a3 3 0 0 0-3-3H9a3 3 0 0 0-3 3v2H4v-2a5 5 0 0 1 5-5h6a5 5 0 0 1 5 5v2zm-8-9a6 6 0 1 1 0-12 6 6 0 0 1 0 12zm0-2a4 4 0 1 0 0-8 4 4 0 0 0 0 8z"/></svg>
                            </a-button>
                        </div>
                    <span class="font-semibold w-full" :class="{'flex-1': multiview}">
                        <a-input v-model:value="task.title" @blur="updateTitle" style="width: 99%"/>
                    </span>
                </div>
                <div class="ml-auto items-center gap-2 hidden sm:hidden md:flex" :class="{'mt-1': multiview}">
                    <span class="text-xs flex gap-1 items-center" v-if="totalTime !== 0" @click="showTaskTimes">
                        {{convertTimeHHMMSS(totalTime)}}
                    </span>

                    <a-button class="flex items-center justify-center p-1" title="Added By" v-if="task.created_by !== user.id && !multiview" @click="showAddedBy(task.created_by)">
                        <template #icon>
                            <svg viewBox="0 0 24 24" fill="currentColor" class="h-full">
                                <use :xlink:href="$iconSpriteLocation + '#ri-user-3-line'"></use>
                            </svg>
                        </template>
                    </a-button>

                    <a-button class="flex items-center justify-center p-1" title="Open Jobcard" v-if="task.job_id && !task.remote_tuning_id" @click="openJobcard">
                        <template #icon>
                            <svg viewBox="0 0 24 24" fill="currentColor" class="h-full">
                                <use :xlink:href="$iconSpriteLocation + '#ri-external-link-line'"></use>
                            </svg>
                        </template>
                    </a-button>

                    <a-button class="flex items-center justify-center p-1" title="Open Tuning Portal" v-else-if="task.remote_tuning_id" @click="openTuningPortal">
                        <template #icon>
                            <svg viewBox="0 0 24 24" fill="currentColor" class="h-full">
                                <use :xlink:href="$iconSpriteLocation + '#ri-external-link-line'"></use>
                            </svg>
                        </template>
                    </a-button>

                    <a-button class="flex items-center justify-center p-1" title="Copy Subtasks" v-if="task.subtasks && task.subtasks.length" @click="copySubtasks">
                        <template #icon>
                            <svg viewBox="0 0 24 24" fill="currentColor" class="h-full">
                                <use :xlink:href="$iconSpriteLocation + '#ri-clipboard-line'"></use>
                            </svg>
                        </template>
                    </a-button>

                    <a-button class="flex items-center justify-center p-1" danger title="Uncomplete Subtasks" v-if="task.subtasks && task.subtasks.length && canUncompleteSubtasks">
                        <template #icon>
                            <svg viewBox="0 0 24 24" fill="currentColor" class="h-full">
                                <use :xlink:href="$iconSpriteLocation + '#ri-arrow-go-back-line'"></use>
                            </svg>
                        </template>
                    </a-button>

                    <a-button class="flex items-center justify-center p-1" title="Add Subtask" v-if="!task.subtasks.length" @click="showAddSubtask = !showAddSubtask">
                        <template #icon>
                            <svg viewBox="0 0 24 24" fill="currentColor" class="h-full">
                                <use :xlink:href="$iconSpriteLocation + '#ri-play-list-add-line'"></use>
                            </svg>
                        </template>
                    </a-button>

                    <a-button class="flex items-center justify-center p-1" title="Edit Notes" @click="showNoteEditor = !showNoteEditor" :class="{'active': showNoteEditor}">
                        <template #icon>
                            <svg viewBox="0 0 24 24" fill="currentColor" class="h-full">
                                <use :xlink:href="$iconSpriteLocation + '#ri-pencil-line'"></use>
                            </svg>
                        </template>
                    </a-button>

                    <a-popconfirm title="Are you sure delete this task?" ok-text="Yes" cancel-text="No" @confirm="deleteTask(task)">
                        <a-button class="flex items-center justify-center p-1" danger title="Delete Task">
                            <template #icon>
                                <svg viewBox="0 0 24 24" fill="currentColor" class="h-full">
                                    <use :xlink:href="$iconSpriteLocation + '#ri-delete-bin-6-line'"></use>
                                </svg>
                            </template>
                        </a-button>
                    </a-popconfirm>

                    <timer ref="timer" :playing="playing" :task="task" @started="playTime" @stopped="pauseTime" :userid="user.id" class="mr-2"></timer>

                    <a-select ref="select" v-model:value="task.owner" style="width: 120px" :options="$staff" :field-names="{ label: 'nicename', value: 'id' }" class="text-xs" @change="updateOwner"></a-select>
                </div>
            </div>

            <div class="ml-4 mb-2 flex items-center gap-1 md:hidden" :class="{'mt-2': multiview}">
                <span class="text-xs flex gap-1 items-center" v-if="totalTime !== 0" @click="showTaskTimes">
                    {{convertTimeHHMMSS(totalTime)}}
                </span>

                <!--<a-button class="w-8 h-8 flex items-center justify-center p-1" title="Added By">
                    <template #icon>
                        <svg viewBox="0 0 24 24" fill="currentColor" class="h-full">
                            <use :xlink:href="$iconSpriteLocation + '#ri-external-link-line'"></use>
                        </svg>
                    </template>
                </a-button>-->

                <a-button class="w-8 h-8 flex items-center justify-center p-1" title="Open Jobcard" v-if="task.job_id && !task.remote_tuning_id" @click="openJobcard">
                    <template #icon>
                        <svg viewBox="0 0 24 24" fill="currentColor" class="h-full">
                            <use :xlink:href="$iconSpriteLocation + '#ri-external-link-line'"></use>
                        </svg>
                    </template>
                </a-button>

                <a-button class="w-8 h-8 flex items-center justify-center" title="Copy Subtasks" v-if="task.subtasks.length" @click="copySubtasks">
                    <template #icon>
                        <svg viewBox="0 0 24 24" fill="currentColor" class="h-4">
                            <use :xlink:href="$iconSpriteLocation + '#ri-clipboard-line'"></use>
                        </svg>
                    </template>
                </a-button>

                <a-button class="w-8 h-8 flex items-center justify-center" danger title="Uncomplete Subtasks" v-if="task.subtasks.length && canUncompleteSubtasks">
                    <template #icon>
                        <svg viewBox="0 0 24 24" fill="currentColor" class="h-4">
                            <use :xlink:href="$iconSpriteLocation + '#ri-arrow-go-back-line'"></use>
                        </svg>
                    </template>
                </a-button>

                <a-button class="w-8 h-8 flex items-center justify-center p-1" title="Add Subtask" v-if="!task.subtasks.length" @click="showAddSubtask = !showAddSubtask">
                    <template #icon>
                        <svg viewBox="0 0 24 24" fill="currentColor" class="h-full">
                            <use :xlink:href="$iconSpriteLocation + '#ri-play-list-add-line'"></use>
                        </svg>
                    </template>
                </a-button>

                <a-button class="w-8 h-8 flex items-center justify-center" title="Edit Notes" @click="showNoteEditor = !showNoteEditor" :class="{'active': showNoteEditor}">
                    <template #icon>
                        <svg viewBox="0 0 24 24" fill="currentColor" class="h-4">
                            <use :xlink:href="$iconSpriteLocation + '#ri-pencil-line'"></use>
                        </svg>
                    </template>
                </a-button>

                <a-popconfirm title="Are you sure delete this task?" ok-text="Yes" cancel-text="No" @confirm="deleteTask(task)">
                    <a-button class="w-8 h-8 flex items-center justify-center" danger title="Delete Task">
                        <template #icon>
                            <svg viewBox="0 0 24 24" fill="currentColor" class="h-4">
                                <use :xlink:href="$iconSpriteLocation + '#ri-delete-bin-6-line'"></use>
                            </svg>
                        </template>
                    </a-button>
                </a-popconfirm>

                <timer :playing="playing" :task="task" @started="playTime" @stopped="pauseTime"></timer>

                <a-select ref="select" v-model:value="task.owner" :options="$staff" :field-names="{ label: 'nicename', value: 'id' }" class="text-xs flex-1" v-if="!multiview"
                          @change="updateOwner"></a-select>
            </div>

            <div v-if="task.racelist" class="border-t border-slate-200 py-1">
                <div v-if="task.racelist.job" class="ml-4 grid grid-cols-2 gap-2">
                    <div v-if="task.racelist.job.vehicle">
                        <ul v-if="task.racelist_job">
                            <li class="text-xs flex flex-col md:block"><span class="font-semibold w-28 mr-2 inline-block">Job ID:</span>{{task.racelist_job.id}}</li>
                            <li class="text-xs flex flex-col md:block" v-if="task.racelist_job.vehicle"><span class="font-semibold w-28 mr-2 inline-block">Make/Model:</span>{{task.racelist_job.vehicle.VRMake}} {{task.racelist_job.vehicle.VRModel}}</li>
                            <li class="text-xs flex flex-col md:block" v-if="task.racelist_job.vehicle"><span class="font-semibold w-28 mr-2 inline-block">Reg:</span>{{task.racelist_job.vehicle.VRVrm}}</li>
                            <li class="text-xs flex flex-col md:block" v-if="task.racelist_job.vehicle"><span class="font-semibold w-28 mr-2 inline-block">VIN:</span>{{task.racelist_job.vehicle.VRVin}}</li>
                        </ul>

                        <ul v-else-if="task.racelist.vehicle">
                            <li class="text-xs flex flex-col md:block" v-if="task.racelist.vehicle"><span class="font-semibold w-28 mr-2 inline-block">Make/Model:</span>{{task.racelist.vehicle.VRMake}} {{task.racelist.vehicle.VRModel}}</li>
                            <li class="text-xs flex flex-col md:block" v-if="task.racelist.vehicle"><span class="font-semibold w-28 mr-2 inline-block">Reg:</span>{{task.racelist.vehicle.VRVrm}}</li>
                            <li class="text-xs flex flex-col md:block" v-if="task.racelist.vehicle"><span class="font-semibold w-28 mr-2 inline-block">VIN:</span>{{task.racelist.vehicle.VRVin}}</li>
                        </ul>
                    </div>

                    <div>
                        <h3 class="font-semibold text-xs">Tools Required</h3>
                        <ul v-if="task.racelist.tools_required">
                            <li class="text-xs" v-for="tool in task.racelist.tools_required" :key="tool.key">{{tool.value}}</li>
                        </ul>
                    </div>
                </div>

                <div v-if="task.racelist.vehicle" class="ml-4 grid grid-cols-2 gap-2">
                    <ul v-if="task.racelist.vehicle">
                        <li class="text-xs flex flex-col md:block" v-if="task.racelist.vehicle"><span class="font-semibold w-28 mr-2 inline-block">Make/Model:</span>{{task.racelist.vehicle.VRMake}} {{task.racelist.vehicle.VRModel}}</li>
                        <li class="text-xs flex flex-col md:block" v-if="task.racelist.vehicle"><span class="font-semibold w-28 mr-2 inline-block">Reg:</span>{{task.racelist.vehicle.VRVrm}}</li>
                        <li class="text-xs flex flex-col md:block" v-if="task.racelist.vehicle"><span class="font-semibold w-28 mr-2 inline-block">VIN:</span>{{task.racelist.vehicle.VRVin}}</li>
                    </ul>

                    <div>
                        <h3 class="font-semibold text-xs">Tools Required</h3>
                        <ul v-if="task.racelist.tools_required">
                            <li class="text-xs" v-for="tool in task.racelist.tools_required" :key="tool.key">{{tool.value}}</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="task-notes border-t px-2 py-1" v-if="task.note && task.note !== '' && task.note !== '<p></p>' && !showNoteEditor">
                <div v-html="task.note" class="text-xs"></div>
            </div>

            <div class="task-notes border-t px-2 py-1" v-if="showNoteEditor">
                <text-editor-with-view v-model="task.note" @blur="updateNotes" no-label no-buttons active></text-editor-with-view>
            </div>

            <div v-if="task.subtasks.length">
                <transition-height :opacityClosed="1">
                    <div class="task-subtasks border-t overflow-x-auto" v-if="task.showSubtasks">
                        <table class="w-full">
                            <draggable v-model="taskSubtasks" tag="tbody" item-key="id" handle=".subtask-handle" @end="sortUpdate">
                                <template #item="{element}">
                                    <subtask :key="element.id" :task="task" :subtask="element" @updated-subtask="subtaskUpdated" :disableReorder="disableReorder"></subtask>
                                </template>
                            </draggable>
                        </table>
                    </div>
                </transition-height>
            </div>

            <div class="new-subtask" v-if="(task.subtasks.length && task.showSubtasks) || showAddSubtask">
                <a-input placeholder="New Subtask..." size="small" class="rounded text-xs py-1" @blur="addSubtask(task, $event)" @keyup.enter="addSubtask(task, $event)" v-model:value="newSubtaskText"/>
            </div>
        </div>

        <a-modal v-model:open="taskTimeModalVisible" title="Task Times" class="p-0" :footer="null">
            <table class="w-full">
                <thead>
                    <tr class="bg-slate-50 border-b border-slate-200">
                        <th class="border-r border-slate-200 text-sm px-2 py-1">Start</th>
                        <th class="border-r border-slate-200 text-sm px-2 py-1">End</th>
                        <th class="border-r border-slate-200 text-sm px-2 py-1">Spent</th>
                        <th class="border-r border-slate-200 text-sm px-2 py-1">User</th>
                        <th class="text-sm px-2 py-1">Task List</th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="time in taskTimes" :key="time.id">
                        <td class="border-r border-b border-slate-200 text-xs px-2 py-1">{{formatTime(time.start_time)}}</td>
                        <td class="border-r border-b border-slate-200 text-xs px-2 py-1">{{formatTime(time.pause_time)}}</td>
                        <td class="border-r border-b border-slate-200 text-xs px-2 py-1">{{getTaskTimeSpent(time.start_time, time.pause_time)}}</td>
                        <td class="border-r border-b border-slate-200 text-xs px-2 py-1">{{getUsernameById(time.user)}}</td>
                        <td class="border-b border-slate-200 text-xs px-2 py-1">{{time.task_list}}</td>
                    </tr>
                </tbody>
            </table>
        </a-modal>
    </div>
</template>

<script>
import {Modal} from "ant-design-vue";

let mouseleft = false;
function checkMousePos(e) {
    mouseleft = e.type === "mouseleave";
}
import {defineComponent} from 'vue';
import _ from 'lodash';
import moment from 'moment';
import draggable from 'vuedraggable'
import Autolinker from 'autolinker';
import {useAuthStore} from "@/store/authStore";
const anchorTagRegex = /<a(?:(?:\s+\w+(?:\s*=\s*(?:".*?"|'.*?'|[^'">\s]+))?)+\s*|\s*)\/?>/g,
    targetAttrRegex = /target\s*=\s*(".*?"|'.*?'|[^'">\s]+)/;
export default defineComponent({
    props: {
        element: {
            type: Object,
            required: true
        },
        multiview: {
            type: Boolean,
            default: false
        },
        currentUserID: {
            type: Number,
            default: null
        },
        disableReorder: {
            type: Boolean,
            default: false
        },
        openSubtasks: {
            type: Boolean,
            default: false
        },
    },
    components: {
        draggable
    },
    data() {
        return {
            task: this.element,
            newSubtaskText: "",
            showNoteEditor: false,
            playing: false,
            totalTime: 0,
            taskTimeModalVisible: false,
            taskTimes: [],
            showAddSubtask: false,
            showOnlyOwnSubtasks: false,
        }
    },
    mounted() {
        document.addEventListener("mouseleave", checkMousePos);
        document.addEventListener("mouseenter", checkMousePos);

        if(this.task.note) {
            this.task.note = this.makeLinksOpenInNewWindow(this.task.note);
            this.task.note = Autolinker.link( this.task.note );
        }

        this.totalTime = 0;
        let total = 0;
        if(this.task !== null && this.task.times !== null && this.task.times !== undefined){
            this.task.times.forEach(element => {
                /*const start = new Date(element.start_time);
                let end;
                if(element.pause_time == null) {
                    end = new Date();
                } else {
                    end = new Date(element.pause_time);
                }
                total += end - start;*/
                total += this.getTaskTimeSpent(element.start_time, element.pause_time, true)
            });
        }
        this.totalTime = total;


        if(this.task.title === "newtask") {
            console.log(this.task)
        }
        if(this.task.times) {
            for (let i = 0; i < this.task.times.length; i++) {
                const time = this.task.times[i];
                if(time.pause_time === null && time.user === this.user.id) {
                    this.playing = true;
                }
            }
        }

        this.task.showSubtasks = this.openSubtasks;
    },
    computed: {
        user() {
            return useAuthStore().user || null;
        },
        canUncompleteSubtasks() {
            if(this.task && this.task.subtasks.length) {
                for (let i = 0; i < this.task.subtasks.length; i++) {
                    const subtask = this.task.subtasks[i];
                    if(subtask.pending_completed === true || subtask.completed === true || subtask.pendin_complete === true) {
                        return true;
                    }
                }
            }
            return false;
        },
        taskSubtasks() {
            if(this.showOnlyOwnSubtasks) {
                const s_tasks = [];
                for (let i = 0; i < this.task.subtasks.length; i++) {
                    const s_task = this.task.subtasks[i];
                    if(s_task.owner === this.user.id) {
                        s_tasks.push(s_task);
                    }
                }
                return s_tasks;
            }
            return this.task.subtasks;
        },
        taskIsInProgress() {
            if(this.multiview) {
                const times = this.task.times;
                for (let i = 0; i < times.length; i++) {
                    if (times[i].user === this.task.owner && times[i].pause_time === null) {
                        return true;
                    }
                }
            }
            return false;
        }
    },
    emits: ['addedSubtask', 'refresh', 'startedTask'],
    methods: {
        showSubtasks(element) {
            element.showSubtasks = !element.showSubtasks
            if(element.showSubtasks) {
                localStorage.setItem('lastOpenedTask', element.id)
            } else {
                localStorage.removeItem('lastOpenedTask')
            }
        },
        deleteTask() {
            this.$axios.delete(this.$apiUrl + '/tasklist/tasks/'+ this.task.id +'/delete').then(response => {
                if(response.data.success) {
                    this.$emit('refresh')
                } else {
                    this.$message.error("Failed to delete task")
                }
            }).catch(e => {
                console.log(e)
                this.$message.error("Failed to delete task")
            })
        },
        addSubtask(task, ev) {
            const value = this.newSubtaskText
            if(value.length > 0) {
                this.$axios.post(this.$apiUrl + '/tasklist/tasks/'+ this.task.id +'/subtasks',{
                    owner: this.user.id,
                    title: value
                }).then(resp => {
                    if(resp.data.success) {
                        const task = resp.data.data;
                        task.showSubtasks = true;
                        this.task = task
                        this.newSubtaskText = "";
                        if(ev.type !== 'blur') {
                            ev.target.blur()
                        }
                        this.showAddSubtask = false;
                    } else {
                        console.log(resp.data)
                    }
                }).catch(e => {
                    console.log(e)
                })
            }
        },
        copySubtasks() {
            let rowtext = "" ;
            this.task.subtasks.forEach(element => {
                if(element.title) {
                    rowtext += element.title + "\r\n";
                    if(element.note) {
                        rowtext += element.note + "\r\n\r\n";
                    }
                } else {
                    rowtext += element.part_name + "\r\n";
                    if(element.note) {
                        rowtext += element.note + "\r\n\r\n";
                    }
                }
            });
            navigator.clipboard.writeText(rowtext).then(() => {
                this.$message.success('Copied to Clipboard')
            })

        },
        updateNotes() {
            this.$axios.post(this.$apiUrl + '/tasklist/tasks/'+ this.task.id +'/update/note',{
                job_id: this.task.job_id,
                note: this.task.note
            }).then(resp => {
                if(resp.data.success){
                    if(mouseleft === true) {
                        this.task.note = this.formatNotes(this.task.note);
                    } else {
                        this.showNoteEditor = false
                        this.task.note = this.formatNotes(this.task.note);
                    }
                } else {
                    console.log(resp.data)
                }
            }).catch(e => {
                console.log(e)
            })
        },
        updateTitle(e){
            this.task.title = e.target.value;
            this.$axios.post(this.$apiUrl + '/tasklist/tasks/'+ this.task.id +'/update/title',{
                title: e.target.value
            }).then(resp => {
                console.log(resp.data)
            }).catch(e => {
                console.log(e)
            })
        },
        formatNotes(s) {
            if(s !== "" && s !== null && s !== undefined) {
                s = s.replace(/Â\s/g,'');
                s = s.replace(/^\s*/g,'');
                s = s.replace(/<p><br><\/p>/g,'');
                s = _.unescape(s);
                return Autolinker.link( s )
            } else {
                return s
            }
        },
        getTimeSpent(start, end) {
            const timer = setInterval(() => {
                if(this.playing === true) {
                    let endT;
                    let startT;
                    if(end == null){
                        endT = moment()
                    } else {
                        endT = moment(end.replace(/\s/, 'T'), 'YYYY-MM-DD H:mm:ss');
                    }
                    if(start == null){
                        startT = moment()
                    } else {
                        startT = moment(start.replace(/\s/, 'T'), 'YYYY-MM-DD H:mm:ss');
                    }

                    let a;
                    let b;
                    if(end == null) {
                        a = endT;
                        b = startT;
                        this.timeSpent = this.convertTimeHHMMSS(a.diff(b) / 1000);
                    } else {
                        a = endT;
                        b = startT;
                        this.timeSpent = this.convertTimeHHMMSS(a.diff(b) / 1000);
                    }
                } else {
                    clearInterval(timer);
                }
            }, 500);
        },
        convertTimeHHMMSS(val) {
            /*let hhmmss = new Date(val * 1000).toISOString().substr(11, 8);
            return hhmmss.indexOf("00:") === 0 ? hhmmss.substr(3) : hhmmss;*/
            var pad = function(num, size) { return ('000' + num).slice(size * -1); },
                time = parseFloat(val/1000).toFixed(3),
                hours = Math.floor(time / 60 / 60),
                minutes = Math.floor(time / 60) % 60,
                seconds = Math.floor(time - minutes * 60)
                //milliseconds = time.slice(-3);

            return pad(hours, 2) + ':' + pad(minutes, 2) + ':' + pad(seconds, 2)// + ',' + pad(milliseconds, 3);
        },
        playTime() {
            if(this.playing === false) {
                this.$emit('startedTask', this.task.id)
                const formData = new FormData();
                formData.append('user', this.user.id);
                formData.append('taskList', this.user_id);
                this.$axios.post(this.$apiUrl + '/tasklist/tasks/'+ this.task.id +'/play', formData).then(resp => {
                    if(resp.data === "Jobcard is closed!") {
                        alert("Jobcard is closed - Collected / Paid");
                    } else {
                        this.playing = true;
                    }

                    if(this.task.job && this.task.job.project_car) {
                      Modal.confirm({
                        title: 'Confirm',
                        content: 'HAVE YOU GOT A FUCKING CAMERA YOU WANKER?',
                        okText: 'YES',
                        cancelText: 'NO',
                        onCancel: () => {
                          Modal.confirm({
                            title: 'Confirm',
                            content: 'ARE YOU SURE A CAMERA IS NOT REQUIRED?',
                            okText: 'YES',
                            cancelText: 'NO',
                            onCancel: () => {
                              this.sendEmailToMedia(true, this.task.job.id)
                            },
                            onOk: () => {
                              this.sendEmailToMedia(false, this.task.job.id)
                            }
                          })
                        }
                      });
                    }
                }).catch(e => {
                    console.log(e)
                })
            }
        },
        sendEmailToMedia(needsCamera, job_id) {
          this.$axios.post(this.$apiUrl + '/tasklist/tasks/'+ this.task.id +'/email/media', {needCamera: needsCamera, job_id: job_id}).then(resp => {
            console.log(resp.data)
          }).catch(err => {console.log(err)});
        },
        pauseTime() {
            const formData = new FormData();
            formData.append('user', this.user.id);
            formData.append('taskList', this.user_id);
            this.$axios.post(this.$apiUrl + '/tasklist/tasks/'+ this.task.id +'/pause', formData).then(response => {
                this.playing = false;
                this.$axios.get(this.$apiUrl + '/tasklist/tasks/'+ this.task.id +'/check/' + response.data.times[0].id).then((resp) => {
                    if(resp.data === false) {
                        const qst = prompt('No subtasks completed during time, what was you doing during the task?')
                        this.$axios.post(this.$apiUrl + '/tasklist/tasks/'+ this.task.id +'/check/' + response.data.times[0].id + '/note', {
                            note: qst
                        }).then((res) => {
                            console.log(res.data)
                        })
                    }
                }).catch((err) => {
                    console.log(err)
                })
            }).catch(e => {
                console.log(e)
            })
        },
        showTaskTimes() {
            this.taskTimeModalVisible = true;
            this.taskTimes = this.task.times;
        },
        getTaskTimeSpent(start, end, milliseconds = false) {
            let startT = moment(start.replace(/\s/, 'T'), 'YYYY-MM-DD H:mm:ss');
            let endT;
            if(end == null){
                endT = moment()
            } else {
                endT = moment(end.replace(/\s/, 'T'), 'YYYY-MM-DD H:mm:ss');
            }

            let a;
            let b;
            if(end == null) {
                a = endT;
                b = startT;
                if(milliseconds) {
                    return a.diff(b)
                } else {
                    return this.convertTimeHHMMSS(a.diff(b));
                }
            } else {
                a = endT;
                b = startT;
                if(milliseconds) {
                    return a.diff(b)
                } else {
                    return this.convertTimeHHMMSS(a.diff(b));
                }
            }
        },
        formatTime(time) {
            return moment(time, 'YYYY-MM-DD H:mm:ss').format('DD-MM-YYYY H:mm:ss');
        },
        updateCompleted() {
            this.$axios.post(this.$apiUrl + '/tasklist/tasks/' + this.task.id + '/update/completion',{
                status: this.task.pending_completed,
                owner: this.task.owner
            }).then(resp => {
                console.log(resp.data)
            }).catch(e => {
                console.log(e)
            }).finally(() => {
                // this.$emit('completionChanged', this.task.pending_completed)
            })
        },
        updateCompletedJobpart(subtask) {
            this.$axios.post(this.$apiUrl + '/tasklist/tasks/'+ this.task.id +'/subtask/update/completion',{
                subtask_id: subtask.id,
                status: subtask.pendin_complete,
                owner: subtask.owner,
                jobpart: true
            }).then(resp => {
                console.log(resp.data)
            }).catch(e => {
                console.log(e)
            })
        },
        openJobcard() {
            window.open("https://jobs.darkside-developments.com/jobsheet/" + this.task.job_id, '_blank');
        },
        openTuningPortal() {
            window.open("https://tuning.darkside-developments.com/job/" + this.task.remote_tuning_id, '_blank');
        },
        sortUpdate() {
            this.$axios.post(this.$apiUrl + '/tasklist/tasks/'+ this.task.id +'/subtasks/reorder',{
                sort: this.task.subtasks
            }).then(resp => {
                console.log(resp)
            }).catch(e => {
                console.log(e)
            })
        },
        updateOwner(e) {
            this.$axios.post(this.$apiUrl + '/tasklist/tasks/'+ this.task.id +'/update/owner',{
                owner: e
            }).then(response => {
                if(response.data.success) {
                    this.$emit('refresh')
                    //location.reload();
                }
            }).catch(e => {
                console.log(e)
            })
        },
        subtaskUpdated() {
            this.$axios.get(this.$apiUrl + '/tasklist/tasks/'+ this.task.id +'/subtasks').then(response => {
                this.task.subtasks = response.data.data
                this.$emit('refresh')
            }).catch(e => {
                console.log(e)
            })
        },
        makeLinksOpenInNewWindow( html ) {
            return html.replace( anchorTagRegex, function( anchorTag ) {
                var targetAttrMatch = anchorTag.match( targetAttrRegex );  // find any pre-existing 'target' attribute
                if( targetAttrMatch ) {
                    // For the case where the 'target' attr exists, and it is something other
                    // than "_blank", replace it. We always want it to be "_blank".
                    var targetAttrValue = targetAttrMatch[ 1 ].replace( /['"]/g, "" );  // strip away any quote characters from the value
                    if( targetAttrValue !== "_blank" ) {
                        anchorTag = anchorTag.replace( targetAttrRegex, 'target="_blank"' );
                    }
                } else {
                    // no target attribute, add it
                    anchorTag = anchorTag.substr( 0, anchorTag.length - 1 ) + ' target="_blank">';
                }

                return anchorTag;
            } );
        },
        showAddedBy(id) {
            const find = this.$staff.find(el => el.id === id);
            if(find) {
                this.$message.info("Created by: " + find.nicename)
            }
            //this.$message.error("Failed to delete task")
        },
        getUsernameById(id) {
        const find = this.$staff.find(el => el.id === id);
        if(find) {
          return find.nicename;
        } else {
          return id;
        }
      }
    },
    beforeUnmount() {
        document.removeEventListener("mouseleave", checkMousePos)
        document.removeEventListener("mouseenter", checkMousePos)
    }
});
</script>
<style lang="scss">
.task-view.multiview {
    flex-flow: wrap;
    flex-direction: row;
    align-items: center;
}
.task-notes {
    overflow-wrap: anywhere;
    border-color: var(--card-background-border);
    color: var(--color-muted);
}
.task .ant-btn-icon-only.ant-btn-sm {
    height: 100%;
    width: inherit;
}
.task .ant-checkbox, .task .ant-checkbox .ant-checkbox-inner{
    height: 20px;
    width: 20px;
    background: var(--button-background);
    border-color: var(--button-border-color);
}
.task .ant-checkbox .ant-checkbox-inner::after {
    left: 27.5%;
}
</style>
